<template>
  <div>
    <div id="hw-hero">
      <headerSite></headerSite>      
      <!-- PAGE HEADER -->
      <div id="page-header" class="contact">
        <div class="title-breadcrumbs">
          <h1>PLANOS</h1>
          <div class="thebreadcumb">
            <ul class="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li class="active">Planos</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- END OF PAGE HEADER -->
    </div>

    <pricingSite></pricingSite>
    <footerSite></footerSite>
  </div>
</template>

<script>
import footerSite from "./template/footer";
import headerSite from "./template/header";
import pricingSite from "./template/pricing";

export default {
  components: {
    headerSite,
    footerSite,
    pricingSite,
  }
};
</script>