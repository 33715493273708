
import Vue from 'vue';
import Router from 'vue-router';
import Site from '../components/Site'
// import Schedule from '../components/Schedule'
import Schedule from '../views/schedule'
import QuemSomos from '../views/main-site/quem-somos'
import Plano from '../views/main-site/planos'
import Contato from '../views/main-site/contato'
import Login from '../views/main-site/login'

Vue.use(Router);

export default new Router({

    hashbang: false,
    history: true,
    mode: 'history',
    routes:[
        {
            path:'/',
            name:'',
            component: Site
        },
        {
            path:'/home',
            name:'inicio',
            component: Site
        },
        {
            path:'/login',
            name:'login',
            component: Login
        },
        {
            path:'/quemsomos',
            name:'sobre',
            component: QuemSomos,
            meta: {
                title: 'Home Page - Example App',
            }
        },
        {
            path:'/planos',
            name:'planos',
            component: Plano
        },
        {
            path:'/contato',
            name:'contato',
            component: Contato,
        },
        {
            path:'/:company',
            name:'',
            component: Schedule,
        },
    ]
});