<template>
  <div>
    <!-- Bootstrap core CSS -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.8.0/css/bootstrap-datepicker.css">
    <link rel="stylesheet" href="style.css">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.0/font/bootstrap-icons.css">
      
    <!-- <headerSchedule :urlLogo="urlLogo" :companyName="companyName"></headerSchedule> -->
      <!-- <nav class="navbar navbar-expand-md navbar-dark fixed-top navbar-brow"> -->
        <!-- <div class="row" style="display: flex;">
          <div class="logo" style="margin-left: auto; margin-right: auto;">
            <img v-if="urlLogo" v-bind:src="'https://localhost:44357/api/Company/image?logoName=' + urlLogo">
            <p class="h3 text-center" style="float: right; margin-left: 10px; margin-top: 35px;" >{{companyName}}</p>
          </div>
        </div> -->
      <!-- </nav> -->
      <main role="main" class="bg-main-site">
        <div class="jumbotron bg-main-site p-4 p-md-5 rounded ">
          <div class="container">
            <div class="col-md-12 px-0">
              <div class="row">
                <div class="col-6 logo">
                  <img 
                    v-if="urlLogo" 
                    v-bind:src="'https://service.horabela.com.br/api/Company/image?logoName=' + urlLogo">
                </div>
                <div class="col-6" style="text-align: center;">
                  <p class="h3"><b>{{companyName}}</b></p>
                  <p v-if='!description'>Agora você pode fazer uma marcação utilizando o seu celular. 
                  <br>Selecione o serviço, dia e melhor horário para você.</p>
                  <p v-if='description'>{{description}}</p>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 px-0"> -->
              <!-- <h1 class="display-5">AGENDE SEU HORÁRIO E NÃO PERCA TEMPO ESPERANDO!</h1> -->
              <!-- <p v-if='!description'>Agora você pode fazer uma marcação utilizando o seu celular. 
                  Selecione o serviço, dia e melhor horário para você.</p>
              <p v-if='description'>{{description}}</p> -->
            <!-- </div>               -->
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="social-media">
                <ul>
                  <li v-if="instagram">
                    <a v-bind:href="'https://www.instagram.com/' + instagram"
                      class="h1 mb-0"
                      target="_blank">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li v-if="whatsapp">
                    <a v-bind:href="'https://api.whatsapp.com/send?phone=' + whatsapp"
                      class="h1 mb-0"
                      target="_blank">
                      <i class="bi bi-whatsapp"></i>
                    </a>
                  </li>
                  <li v-if="youtube">
                    <a v-bind:href="'https://www.youtube.com/' + youtube"
                      class="h1 mb-0"
                      target="_blank">
                      <i class="bi bi-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2>HORÁRIOS DE FUNCIONAMENTO</h2>
                <ul>
                  <li v-for="program in programs" 
                      v-bind:key="program.nameTypeProgram">
                      {{program.nameTypeProgram + " "+ program.time}}
                  </li>
                </ul>                    
              </div>
              <div>
                <h2 v-if="street">ENDEREÇO</h2>
                <p v-if="street">
                  {{ street + ','}}
                  {{ number? 'nº '+ number + ', ' : ''}}
                  {{ complement? 'Compl. '+ complement + ', ' : ''}}
                  {{ neighborhood? neighborhood + ', ' : ''}}
                  {{ city? city + ' - ' : ''}}
                  {{ state? state + ' ' : ''}}
                  {{ country? country + ' ' : ''}}
                </p>
              </div>
            </div>
            <div class="col-md-6" style="padding-left: 20px;">
              <!-- <h2 class="display-5">AGENDE SEU HORÁRIO E NÃO PERCA TEMPO ESPERANDO!</h2> -->
              <div class="app-schedule app" >
                <h2 style="text-align: center;" class="label-white">Agende em apenas 1 minuto.</h2>
                <form>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="label-white">Seu nome</label>
                      <input  
                        type="text" 
                        maxlength="50" 
                        class="form-control"
                        name="clientName" 
                        v-model="clientName" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="label-white">Telefone DDD+Numero</label>
                      <input  
                        type="tel" 
                        maxlength="14" 
                        size="14" 
                        class="form-control" 
                        name="fone" 
                        v-model="fone"
                        placeholder="(00) 0000-0000"
                        @keyup="phoneFormatter"/>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="label-white">Serviço</label>
                        <div class="form-group ">
                          <select class="custom-select form-control" 
                            v-model="serviceSelected"
                            v-on:change="getTimes">
                            <option disabled value="">Selecione um serviço</option>
                            <option 
                              v-for="service in services" 
                              v-bind:key="service.name" 
                              v-bind:value="service.name">
                              {{service.name}}
                            </option>
                          </select>
                        </div>
                    </div>
                  </div>
                  <div class="form-row" v-if="useProfessional">
                    <div class="form-group col-md-12">
                      <label class="label-white">Profisional a realizar o serviço</label>
                      <select 
                        class="custom-select form-control" 
                        v-model="barberSelected"
                        v-on:change="getTimes">
                        <option
                          v-for="barber in barbers" 
                          v-bind:key="barber.name" 
                          v-bind:value="barber.id">
                          {{barber.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="label-white" for="inputDate">Data</label>
                      <!-- <input type="date" id="datepicker" class="form-control "  v-model="dateSelected" > -->
                      <datepicker 
                        :language="ptBR" :format="format"  
                        :disabled-dates="disabledDates"
                        @input="getTimes" 
                        v-model="dateSelected">
                      </datepicker>                                  
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="label-white">Hora</label>
                      <select class="custom-select form-control" v-model="timeSelected">
                        <option 
                          v-for="time in times" 
                          v-bind:key="time" 
                          v-bind:value="time" >
                          {{time}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <button 
                        type="button" 
                        v-on:click="newSchedule" 
                        class="btn btn-agendar btn-lg btn-block">Agendar
                      </button>                    
                    </div>
                  </div>
                  <div class="form-row label-white">
                    <div v-if="mensagem" class="alert alert-success" role="alert">{{mensagem}}</div>
                    <p v-if="errors.length>0">
                      <label>Por favor, corrija o(s) campo(s):</label>
                      <ul>
                        <li v-for="error in errors" v-bind:key="error" >{{ error }}</li>
                      </ul>
                    </p>				
                  </div>	
                </form>
              </div>
            </div>
          </div>
          <br>
        </div> <!-- /container -->
      </main>
      <footer class="container bg-foote-site">
        <p>© Copyright HoraBela 2020-2022</p>
      </footer>
  </div>    
</template>

<style>
  .vdp-datepicker div input {
    font-size: 18px;
    height: 55px;
    padding: 5px 15px;
    width: 100%;
  }
</style>


<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Vue from 'vue'
import money from 'v-money'

Vue.use(money, {precision: 4});

export default {
  components: {
    Datepicker,
  },
  data() {    
      return {
          //phone: '(###)###-####',
          money: {
            decimal: ',',
            thousands: '.',
            prefix: '',
            suffix: '',
            precision: 2,
            masked: false /* doesn't work with directive */
          },
          disabledDates:{
            to: Date,
            from: Date,
            dates: [],
          },
          ptBR: ptBR,
          format: "dd/MM/yyyy",
          //urlBase: 'http://192.168.0.113:5000/api/',
          urlBase: 'https://service.horabela.com.br/api/',
          logo: null,
          companyName: null,
          errors: [],
          mensagem: null,
          barbers:[],
          times: [],
          fone: null,
          barberSelected: null,
          dateSelected: null,
          timeSelected: null,
          dataInicial:null,
          clientName: null,
          useProfessional:Boolean,
          programs:[],
          services:[],
          ToDate:Date,
          FromDate:Date,
          street: '',
          neighborhood: '',
          city: '',
          state: '',
          number: '',
          complement: '',
          zipCode: '',
          country: '',
          description: '',
          instagram: '',
          youtube: '',
          whatsapp: '',
          serviceSelected: '',
      }
    },
    filters: {
      toMoney(value) {
        if (typeof value !== "number") {
          return value;
      }
      var formatter = new Intl.NumberFormat('pt', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
      },
    },
    mounted () {
      axios
        .get(this.urlBase + 'Scheduler/'+ this.$route.params.company)
        .then(response => ( 
          this.dataInicial = response.data,
          this.urlLogo = response.data.logo,
          this.companyName = response.data.companyName,
          this.useProfessional = response.data.useProfessional,
          this.programs = response.data.programs,
          this.services = response.data.services,
          this.barbers = response.data.barbers,
          this.disabledDates.to = new Date(response.data.toDate),
          this.disabledDates.from = new Date(response.data.fromDate),
          this.disabledDates.dates = this.formartDate(response.data.dates),
          this.street = response.data.street,
          this.neighborhood = response.data.neighborhood,
          this.city = response.data.city,
          this.state = response.data.state,
          this.number = response.data.number,
          this.complement = response.data.complement,
          this.zipCode = response.data.zipCode,
          this.country = response.data.country,
          this.description = response.data.description,
          this.instagram = response.data.instagram,
          this.youtube = response.data.youtube,
          this.whatsapp = response.data.whatsapp,
          this.serviceSelected = ''
        ))
    },
    methods: 
    {
      newSchedule: function(){
        this.errors = [];

        if(this.clientName == null || this.clientName == ''){
          this.errors.push("Preencha seu nome!");
        }
        if(this.fone == null || this.fone == ''){
          this.errors.push("Preencha seu número de Telefone!");
        } 
        else if (!this.phoneValid(this.fone)) {
          this.errors.push("Número de Telefone inválido!");
        }        

        if(this.serviceSelected === ''){
          this.errors.push("Escolha um Serviço a ser realizado!");
        }

        if(this.dateSelected == null){
          this.errors.push("Escolha uma Data para realizar o serviço!");
        }

        if(this.useProfessional){
          if(this.barberSelected.length == 0)
            this.errors.push("Escolha um profissional!");
        }          

        if(this.timeSelected == null){
          this.errors.push("Escolha um Horário para realizar o serviço!");
        }

        if(this.errors.length > 0){
          return;
        }


        if(!this.error) {
          const requestOptions = {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
              // "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
            },
            body: JSON.stringify({
              fone: this.fone,
              nameCompany: this.$route.params.company,
              services: [this.serviceSelected],
              barber: this.barberSelected,
              dateTime: this.converterDate(this.dateSelected),
              Hora: this.timeSelected,
              clientName: this.clientName
            })
          };

          fetch(this.urlBase + 'Scheduler', requestOptions)
          .then(async response => {
            if (!response.ok) {
              const data = await response.json();

              if(data.StatusCode === 400){
                this.getTimes();
                alert(data.Message);
              }
            } else {
              this.clientName = '';
              this.timeSelected = null;
              this.serviceSelected = '';
              this.fone = null;
              this.barberSelected = null;
              this.times = [];
              alert("Agendamento realizado com sucesso!");  
            }
          })
          .catch(error => {
            console.log(error);
            alert("Não foi possivel realizar o agendamento. Tente novamente!");
          });
        }
      },
      getTimes:function() {
        this.timeSelected = null;
        if(this.dateSelected === null || this.serviceSelected === ''){
          return;
        }

        var params = new URLSearchParams();
        params.append("date", this.converterDate(this.dateSelected));

        if(this.useProfessional){
          params.append("idCollaborator", this.barberSelected);
        }

        params.append("servicesName", this.serviceSelected)

        this.times = null;
        axios.get(this.urlBase + 'Scheduler/times/'+ this.$route.params.company , {
                params: params,
            })
            .then(response => ( 
              this.times = response.data
            ))
            .catch(e => {
                console.log(e);
            });
      },
      formartDate:function(arrayData){
        var dates = [];
        arrayData.forEach(element => {
          dates.push(new Date(element))
        });

        return dates;
      },
      converterDate:function(value){
        if (value) {
          return moment(String(value)).format('YYYY-MM-DD')
        }
      },
      converterDatePTBR:function(value){
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY')
        }
      },
      phoneValid (phone) {
        var regex = new RegExp('^\\([0-9]{2}\\)(([0-9]{5}-[0-9]{4})|([0-9]{5}-[0-9]{3}))$');
        return regex.test(phone);
      },
      phoneFormatter(event) {
        let phone = event.target.value;
        phone = phone.replace(/\D/g, "");

        const parte1 = phone.slice(0, 2);
        const parte2 = phone.slice(2, 7);
        const parte3 = phone.slice(7, 11);

        //TODO inpruve this logic
        if (phone.length > 2) event.target.value = `(${parte1})` 
        else event.target.value = parte1;

        if (parte2 !== '') event.target.value += '' + parte2;
        if (parte3 !== '') event.target.value += '-'+ parte3;
      }
    }
}
</script>

<style scoped>
  .logo { text-align: center; }
  .logo img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
</style>