<template>
  <div>
    <div id="hw-hero">
      <headerSite></headerSite>
      <div id="page-header" class="contact">
        <div class="title-breadcrumbs">
          <h1>SOBRE NÓS</h1>
          <div class="thebreadcumb">
            <ul class="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li class="active">Sobre Nós</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <section class="introtext">
      <div class="row">
        <div class="col-sm-12">
          <h2>
            Hora Bela
            <span>Otimizando</span> seu tempo
          </h2>
          <p class="text-center">
            Nascemos pela necessidade de gerar valor para seu estabelecimento, solucionando problemas principalmente relacionado a agendamento. 
            Com foco no cliente, sempre buscamos prestar o melhor serviço possível agregando valor para sua empresa.
             Foque na sua atividade e deixe que nós levamos para você a melhor experiencia para a sua agenda.
          </p>
        </div>
      </div>
      <div class="spacing-65"></div>
      <div class="row custom-width-90">
        <div class="col-sm-12">
          <div id="about2-video">
            <div class="about2-video-container">
              <div class="videocaption">
                <div class="videocaption-content wow fadeIn">
                  <h2>ESTAMOS SEMPRE PENSANDO COMO FAZER MELHOR</h2>
                  <hr class="small" />
                  <p>E para isso acontecer estamos sempre abertos para conversar e entender melhor a sua necessidade para construirmos o melhor para você. 
                    Por isso, caso queira entrar em <a href="contato">contato</a>, teremos o maior frazer em conversar com você.
                  </p>
                </div>
              </div>
              <img src="main-site/images/video-poster.jpg" style="height:500px ">
            </div>
          </div>
        </div>
      </div>
    </section>
    <footerSite></footerSite>
  </div>
</template>

<script>
import footerSite from "./template/footer";
import headerSite from "./template/header";

export default {
  components: {
    headerSite,
    footerSite
  }
};
</script>