<template>
  <div>
    <div id="hw-hero">
      <headerSite></headerSite>

      <!-- PAGE HEADER -->
      <div id="page-header" class="contact">
        <div class="title-breadcrumbs">
          <h1>LOGIN</h1>
          <div class="thebreadcumb">
            <ul class="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li class="active">Login</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- END OF PAGE HEADER -->
    </div>

    <section class="content calculate-shipping">
      <div class="row">
        <div class="col-sm-6">
          <div class="shop-cart-bordered">
            <h3>Login</h3>

            <form>
              <input
                type="text"
                class="form-control"
                id="email"
                placeholder="E-mail"
              />
              <input
                type="text"
                class="form-control"
                id="password"
                placeholder="Senha"
              />
              <button type="submit" class="btn btn-primary">Entrar</button>
            </form>
          </div>
        </div>

        <div class="col-sm-6">
            <div class="shop-cart-bordered">
            <h3>Cadastre - se</h3>

            <form>
              <input
                type="text"
                class="form-control"
                id="estabelecimento"
                placeholder="Nome do estabelecimento"
              />
              <input
                type="text"
                class="form-control"
                id="email"
                placeholder="E-mail"
              />
              <input
                type="text"
                class="form-control"
                id="telefone"
                placeholder="Telefone"
              />
              <button type="submit" class="btn btn-primary">Cadastrar</button>
            </form>
          </div>
        </div>
      </div>
    </section>

    <footerSite></footerSite>
  </div>
</template>

<script>
import footerSite from "./template/footer";
import headerSite from "./template/header";

export default {
  components: {
    headerSite,
    footerSite
  }
};
</script>