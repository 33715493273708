<template>
<div>
  <div id="hw-hero">
    <headerSite></headerSite>
    <sliderSite></sliderSite>
  </div>
  <section class="pricingtables">
    <div class="row no-gutter">
      <div class="panel panel-info">
        <div>
          <h2>Você já é cadastrado? Realize seu <a href="https://app.horabela.com.br/login">login</a>.</h2>
          <h2>Ainda não! <a href="https://app.horabela.com.br/registro">Cadastre-se agora</a> </h2>
        </div>
      </div>
    </div>
  </section>
  <section class="features">
    <div class="row">
      <div class="col-md-4">
        <h3>Benefícios</h3>
        <div class="feature">
          <div class="featureicon">
            <span class="typcn typcn-business-card"></span>
          </div>
          <h5>Página de Marcações</h5>
          <p>Com sua página online, você disponibiliza seu link no Instagram ou compartilhar no WhatsApp para seus 
            clientes realizarem o agendamento sem precisar baixar aplicativo.</p>
        </div>
        <div class="feature">
          <div class="featureicon">
            <span class="typcn typcn-calendar"></span>
          </div>
          <h5>Agenda Online</h5>
          <p>Você terá uma página administrativa com todos os agendamentos realizados contendo as informações do cliente
             e horários disponíveis. É possível cancelar o agendamento, nesse caso o sistema irá enviar um SMS (mensagem 
             de texto) para o telefone do seu cliente, o notificando.</p>
        </div>
        <div class="feature">
          <div class="featureicon">
            <span class="typcn typcn-cog-outline"></span>
          </div>
          <h5>Configure seus horários e serviços</h5>
          <p>Você pode fornecer as informações do estabelecimento para sua página ficar bem apresentável e contendo as 
            informações importantes, você também pode configurar seus horários disponíveis e serviços prestados para 
            personalizar sua agenda.</p>
        </div>
      </div>
      <div class="col-md-8">
        <img src="main-site/images/features-img.jpg" alt />
      </div>
    </div>
  </section>
  <featuresSite></featuresSite>
  <pricingSite></pricingSite>  
  <formRegister></formRegister>
  <footerSite></footerSite>
  <a href="#top" id="back-to-top">
    <i class="fa fa-angle-up"></i>
  </a>
</div>
</template>

<script>
import footerSite from './template/footer'
import headerSite from './template/header'
import sliderSite from './template/slider'
import pricingSite from './template/pricing'
import featuresSite from './template/features'

export default {
  components: {
    footerSite,
    headerSite,
    sliderSite,
    pricingSite,
    featuresSite,
  }
};
</script>