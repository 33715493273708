<template>
    <MainSite></MainSite>
</template>

<script>

import  MainSite from '../views/main-site/index'

export default {
    components:{
        MainSite
    }

}
</script>