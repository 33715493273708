<template>
<div>
  <div id="hw-hero">
    <headerSite></headerSite>

    <!-- PAGE HEADER -->
    <div id="page-header" class="contact">
      <div class="title-breadcrumbs">
        <h1>CONTATO</h1>
        <div class="thebreadcumb">
          <ul class="breadcrumb">
            <li>
              <a href="#">Home</a>
            </li>
            <li class="active">Contato</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- END OF PAGE HEADER -->
  </div>

  <!--  ICON BOXES -->
  <div class="about1-numbers contact">
    <div class="row">
      <div class="col-sm-4">
        <div class="shadow-effect">
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <div class="number lightgray">
                <span class="typcn typcn-mail"></span>
              </div>
            </div>
            <div class="col-sm-12 col-md-9">
              <h4>INFORMAÇOES</h4>
              <p>Caso queira falar com nosso comercial, adiquirir nossos serviços ou saber mais informações.</p>
              <span>
                <a href>contato@horabela.com.br</a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="shadow-effect">
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <div class="number blue">
                <span class="typcn typcn-heart"></span>
              </div>
            </div>
            <div class="col-sm-12 col-md-9">
              <h4>CONTATO PARA SUPORTE</h4>
              <p>Caso necessite tirar duvidas ou assuntos relacionados.</p>
              <span>
                <a href>contato@horabela.com.br</a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="shadow-effect">
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <div class="number green">
                <span class="typcn typcn-location"></span>
              </div>
            </div>
            <div class="col-sm-12 col-md-9">
              <h4>NOSSO ESCRITÓRIO</h4>
              <p>
                Recife - PE
                <br />
                <br />
              </p>
              <span>Fone: (81) 99628-0480</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  END OF ICON BOXES -->

  <!--  FORM -->
  <section class="contact-form">
    <div class="row">
      <div class="col-sm-12">
        <h2>
          ENVIE SUA
          <span>MENSAGEM</span>
        </h2>
        <hr class="small" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="sendstatus"></div>

        <div id="contactform">
          <form method="post" action="sendmail.php">
            <div class="row">
              <div class="col-sm-6">
                <p>
                  <label for="name">Nome:*</label>
                  <input type="text" class="form-control" name="name" id="name" tabindex="1" />
                </p>
                <p>
                  <label for="email">Email:*</label>
                  <input type="text" class="form-control" name="email" id="email" tabindex="2" />
                </p>
                <p>
                  <label for="phone">Telefone:*</label>
                  <input type="text" class="form-control" name="phone" id="phone" tabindex="3" />
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <label for="comments">Assunto:*</label>
                  <textarea class="form-control" name="comments" id="comments" tabindex="4"></textarea>
                </p>
              </div>
            </div>
            <p>
              <input
                type="button"
                name="submit"
                id="submit"
                class="mtr-btn button-fab ripple"
                value="Enviar"
              />
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!--  END OF FORM -->
  <div id="map_wrapper">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.371761835415!2d-34.8752157858425!3d-8.063509382805075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18a4c9c9a4c1%3A0xf286bf5ab69e7ba!2sAv.%20Marqu%C3%AAs%20de%20Olinda%20-%20Recife%2C%20PE%2C%2050030-230!5e0!3m2!1spt-BR!2sbr!4v1594599404718!5m2!1spt-BR!2sbr" 
    width="100%" height="100%" frameborder="0" style="border:0; background-color: rgb(229, 227, 223);" allowfullscreen="" aria-hidden="false" tabindex="0">
    </iframe>
  </div>
  <footerSite></footerSite>
</div>
</template>

<script>
import footerSite from './template/footer'
import headerSite from './template/header'

export default {
  components: {
    headerSite,
    footerSite,
  }
};
</script>