<template>
  <div>
    <section class="footer">
      <!-- <div class="footer-elements">
        <div class="row">
          <div class="col-sm-12">
            <div class="row no-gutter">
              <div class="col-sm-3">
                <div class="footer-element">
                  <span class="typcn typcn-watch"></span>
                  <p>
                    SEGUNDA A SEXTA
                    <span>08:00 - 18:00</span>
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="footer-element">
                  <span class="typcn typcn-location-outline"></span>
                  <p>
                    RECIFE - PE
                    <span>.&nbsp;</span>
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="footer-element">
                  <span class="typcn typcn-mail"></span>
                  <p>
                    E-MAIL:
                    <span>
                      <a href>CONTATO@HORABELA.COM.BR</a>
                    </span>
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="footer-element">
                  <span class="typcn typcn-phone-outline"></span>
                  <p>
                    FONE:
                    <span>(81) 99628.0480</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="footer-widgets">
        <div class="row">
          <!-- FOOTER WIDGET -->
          <div class="col-sm-3">
            <div class="footer-widget">
              <div class="small-logo">
                <img src="main-site/images/logo-footer.png" alt />
              </div>
              <p>Sua agenda na palma da mão.</p>
              <ul class="social-links">
                <li>
                  <a href='https://www.instagram.com/horabela/'>
                    <i class="fa fa-instagram"></i>
                  </a>
                  <!-- <a href="#" title="Twitter">
                    <i class="fa fa-twitter"></i>
                  </a> -->
                </li>
                <li>
                  <!-- <a href="#" title="Facebook">
                    <i class="fa fa-facebook"></i>
                  </a> -->
                </li>
              </ul>
            </div>
          </div>
          <!-- END FOOTER WIDGET -->

          <!-- FOOTER WIDGET -->
          <div class="col-sm-3">
            <div class="footer-widget">
              <h5></h5>
              <ul class="posts">
                <li>
                  <a href></a>
                  <span></span>
                </li>
              </ul>
            </div>
          </div>
          <!-- END FOOTER WIDGET -->

          <!-- FOOTER WIDGET -->
          <div class="col-sm-3">
            <div class="footer-widget">
              <h5>LINKS</h5>
              <ul class="posts">
                <li>
                  <a href='login'>LOGIN</a>
                </li>
                <li>
                  <a href='home'>HOME</a>
                </li>
                <li>
                  <a href='planos'>PLANOS</a>
                </li>
                <li>
                  <a href='quemsomos'>SOBRE NÓS</a>
                </li>
                <li>
                  <a href='contato'>CONTATO</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- END FOOTER WIDGET -->

          <!-- FOOTER WIDGET -->
          <div class="col-sm-3">
            <div class="footer-widget">
              <h5>NEWSLETTER</h5>
              <p>Coloque o seu email abaixo para receber as atualizações</p>
              <div id="mc_embed_signup">
                <form
                  class="form-inline validate material"
                  onsubmit="return false"
                >
                  <input type="email" id="email" name="email" placeholder="E-mail" v-model="email">
                  <input
                    type="submit"
                    value="Enviar"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="mtr-btn button-blue"
                    v-on:click="saveNewslatter"
                  />
                </form>
              </div>
            </div>
          </div>
          <!-- END FOOTER WIDGET -->
        </div>
      </div>
    </section>
    <div class="copyright">
      <div class="row">
        <div class="col-sm-12">
          <p class="text-center">Copyright HoraBela 2022 Inc. Todos os direitos reservados.</p>
        </div>
      </div>
    </div>
    <a href="#top" id="back-to-top" style="display: inline;">
      <i class="fa fa-angle-up"></i>
    </a>
  </div>
</template>


<script>
  export default {
    data() {    
        return {
          urlBase: 'https://service.horabela.com.br/api',
          email: '',
        }
      },
      methods:
      {
        saveNewslatter: function(){
          if (this.email == '') {
            return alert('Preencha um e-mail válido');
          }

          const requestOptions = {
              method: "POST",
              headers: {
                'content-Type': 'application/json',
              },
            };
            fetch(this.urlBase + '/Newsletter/NewsLetterSite?email='+ this.email, requestOptions)
            .then(async response => {

              if (response.ok) {
                this.email = '';
                alert('E-mail cadastrado com sucesso!');
              } else {
                const data = await response.json();
                if(data.StatusCode === 400){
                  alert(data.Message);
                }
              }
            });
        },
      }
  }
</script>