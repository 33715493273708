<template>
  <div>
    <section class="pricingtables">
      <div class="row no-gutter">        
        <div class="price-plans">
          <div class="col-sm-4">
            <div class="panel panel-info">
              <div class="panel-heading">
                <h3 class="text-center">PLANO I</h3>
              </div>
              <div class="panel-body text-center">
                <h4>                  
                  <strike style="font-size: 25px">R$ 19,90</strike><br>
                  R$ 15,90/
                  <span>mês</span><br>
                  <span> Preço por tempo limitado </span>
                </h4>
              </div>
              <ul class="text-center">
                <li>1 Profissional</li>
                <li>Página para agendamento</li>
                <li>Configurações personalisadas</li>
                <li>Agendamentos Ilimitados</li>
                <li>Envio de SMS ao cancelar agendamento</li>
              </ul>
              <div class="panel-footer">
                <button class="btn btn-lg btn-pricetable" v-on:click="selectPlan(1)">ESCOLHER</button>
              </div>
            </div>
          </div>

          <div class="col-sm-4 most-popular">
            <div class="panel panel-info">
              <div class="panel-heading">
                <h3 class="text-center">PLANO II</h3>
              </div>
              <div class="panel-body text-center">
                <h4>
                  <strike style="font-size: 25px">R$ 49,90</strike><br>
                  R$ 29,90/
                  <span>mês</span>
                  <br><span> Preço por tempo limitado </span>
                </h4>
              </div>
              <ul class="text-center">
                <li>de 1 a 3 Profissionais</li>
                <li>Página para agendamento</li>
                <li>Configurações personalizadas</li>
                <li>Agendamentos Ilimitados</li>
                <li>Envio de SMS ao cancelar agendamento</li>
              </ul>
              <div class="panel-footer">
                <button class="btn btn-lg btn-pricetable" v-on:click="selectPlan(2)">ESCOLHER</button>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="panel panel-info">
              <div class="panel-heading">
                <h3 class="text-center">PLANO III</h3>
              </div>
              <div class="panel-body text-center">
                <h4>
                  <strike style="font-size: 25px">R$ 69,90</strike><br>
                  R$ 49,90/
                  <span>mês</span>
                  <br><span>Preço por tempo limitado</span>                  
                </h4>
              </div>
              <ul class="text-center">
                <li>de 4 a 6 Profissionais</li>
                <li>Página para agendamento</li>
                <li>Configurações personalizadas</li>
                <li>Agendamentos Ilimitados</li>
                <li>Envio de SMS ao cancelar agendamento</li>
              </ul>
              <div class="panel-footer">
                <button class="btn btn-lg btn-pricetable" v-on:click="selectPlan(3)">ESCOLHER</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutter">
         <div class="panel panel-info">
              <div >
                <h3 class="text-center"><a v-on:click="selectPlan(null)">Cadastre-se agora</a> e utilize até 10 dias grátis</h3>
                <h3 class="text-center">Cancele quando quiser</h3>
                <h3 class="text-center">Sem anuidade</h3>
                <h3 class="text-center">Sem plano de fidelização</h3>
              </div>
         </div>
      </div>      
    </section>
  </div>
</template>


<script>
export default {

  components: {
  },
  data() {    
      return {                    
          urlBaseAdmin: "https://app.horabela.com.br/registro/",
      }
    },
    methods: 
    {
      selectPlan: function(value){
        if(value === null){
          window.location.href = this.urlBaseAdmin;
        }else{
          window.location.href = this.urlBaseAdmin + value;
        }
      },
    }
}
</script>