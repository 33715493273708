<template>
  <router-view></router-view>
</template>

<script>
// import Schedule from  './components/Schedule';

export default {
   name: 'app',
   components: {
    //  Site
  }
}
</script>